import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AzureBlobStorageService } from "./azure-blob-storage.service";
// import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { environment } from "src/environments/environment.prod";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  containerName = environment.azure.containerName;
  fileList: string[] = [];
  sas = ""; // SAS (shared access signatures)
  fileData: any;
  filename: any;
  AddressablesLoc: any;
  azUpload: FormGroup;
  path: any;
  showLoader: boolean;
  submitted = false;
  result: string = "";
  containerTypes = ["xrconnect-b2b-dev", "xrconnect-b2b-qa", "xrconnect-b2b-prod", "xrconnect-b2c-dev", "xrconnect-b2c-qa", "xrconnect-b2c-prod","staging-container","xrconnect-demo"];
  buildTypes = ['Android', 'Windows', 'IOS','Mac', 'WSA', 'WebGL']
  selectedContainerName = window["env"]["containerType"];
  // selectedBuildType: any;

  constructor(
    private blobService: AzureBlobStorageService,
    private formBuilder: FormBuilder,
    private azservice: AzureBlobStorageService
  ) { }

  ngOnInit(): void {
    this.reloadFileList();
    this.init();
  }
  init() {
    this.azUpload = this.formBuilder.group({
      path: ["Addressables/xrconnect/Android/1/LOW/", Validators.required],
      file: [""],
      buildType: ['Android']
    });
    console.log(this.azUpload.value);

  }

  // UploadFile
  fileSelected1(event) {

    this.fileData = event.target.files;
    for (var i = 0; i < this.fileData.length; i++) {
      this.result += "filename:" + this.fileData[i].name;
      console.log("FileName: ", this.fileData[i].name);
      console.log("setpath: ", this.azUpload.value.path);
    }
  }

  // upload to azure
  public async uploadFileToAzure() {
    this.submitted = true;
    if (!this.fileData || !this.azUpload.value.path) {
      alert("Missing file path and file(s)");
      return;
    } else {
      this.showLoader = true;
      this.AddressablesLoc = this.azUpload.value.path;
      for (var i = 0; i < this.fileData.length; i++) {
        await this.blobService.uploadApplicationFiles(
          this.sas,
          this.fileData[i],
          this.fileData[i].name,
          this.AddressablesLoc,
          () => {
            this.reloadFileList();
          }
        );
      }
      this.azUpload.reset();
      this.reloadFileList();
      alert("Files uploaded successfully.")
    }
    this.showLoader = false;
  }

  // deleteFile
  public deleteFile(name: string) {
    console.log(name);
    this.blobService.deleteFile(this.sas, name, () => {
      this.reloadFileList();
    });
  }

  // ListFile
  private reloadFileList() {
    this.blobService.listFiles().then((list) => {
      // console.log(list);
      this.fileList = list;
    });
  }

  // DownloadFile
  public downloadFile(name: string) {
    this.blobService.downloadFile1(name, (blob) => {
      let url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }
  changeContainerType() {
    let buildType = this.azUpload.get('buildType').value;
    console.log('selected build', buildType);
    // this.selectedBuildType=buildType
    if (buildType === "Android") {
      this.azUpload.patchValue({
        path: 'Addressables/xrconnect/Android/1/LOW/'
      })

    } else if (buildType === "Windows") {
      this.azUpload.patchValue({
        path: 'Addressables/xrconnect/Windows/1/HIGH/'
      })

    }
    else if (buildType === "IOS") {
      this.azUpload.patchValue({
        path: 'Addressables/xrconnect/IOS/1/LOW/'
      })
    }
    else if (buildType === "Mac") {
      this.azUpload.patchValue({
        path: 'Addressables/xrconnect/Mac/1/LOW/'
      })
    }
    else if (buildType === "WSA") {
      this.azUpload.patchValue({
        path: 'Addressables/xrconnect/WSA/1/LOW/'
      })

    }
    else if (buildType === "WebGL") {

      this.azUpload.patchValue({
        path: 'Addressables/xrconnect/WebGL/1/HIGH/'
      })
    }
    
    console.log('patched path', this.azUpload.value);

    this.reloadFileList();
  }

}
