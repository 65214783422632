export const environment = {
  production: true,

  // ###########################################    XRConnect B2B    ###########################################
  // xrconnect-b2b-prod container 
  // azure: 
  //   {
  //     azure_account_name: "xrconn",
  //     containerName: "xrconnect-b2b-prod",
  //     sas : "sp=racwdl&st=2021-08-30T07:57:46Z&se=2021-12-31T18:29:59Z&spr=https&sv=2020-08-04&sr=c&sig=TI5Cn8rYUIhhfwauWbyG5PBsuVZijNI1s8bLQY%2FkvcA%3D"
  //   }

    // xrconnect-b2b-qa container 
  // azure: 
  // {
  //   azure_account_name: "xrconn",
  //   containerName: "xrconnect-b2b-qa",
  //   sas : "sp=racwdl&st=2021-08-25T09:40:16Z&se=2021-12-31T18:29:59Z&spr=https&sv=2020-08-04&sr=c&sig=ocNa57qby01rdc0rDxTRTn81lzUORcpM0XiH9OZD0aI%3D"
  // }

  azure: 
  {
    azure_account_name: window["env"]["azureAccountName"],
    // azure_account_name: window["env"]["azureAccountName"],
    containerName: window["env"]["containerType"],
    sas : window["env"]["sasKey"]
  }
  
  // // xrconnect-b2b-dev container 
  // azure: 
  //   {
  //     azure_account_name: "xrconn",
  //     containerName: "xrconnect-b2b-dev",
  //     sas : "sp=racwdl&st=2021-08-30T07:56:49Z&se=2021-12-31T18:29:59Z&spr=https&sv=2020-08-04&sr=c&sig=jUW4JyhWQwptl2eNrMVa0l1Mfk%2FYnQMXOZLAfbbdDVQ%3D"
  //   }

    // ###########################################    XRConnect B2C    ###########################################
    // 
    // xrconnect-b2c-prod container 
  // azure: 
  //   {
  //     azure_account_name: "xrconn",
  //     containerName: "xrconnect-b2c-prod",
  //     sas : "sp=racwdl&st=2021-08-30T07:59:55Z&se=2021-12-31T18:29:59Z&spr=https&sv=2020-08-04&sr=c&sig=UbrNmBUcSWCqEK7qABUDpURGC8aJ58T1I4ZFjmbCyzM%3D"
  //   }

  // // xrconnect-b2c-qa container 
  // azure: 
  //   {
  //     azure_account_name: "xrconn",
  //     containerName: "xrconnect-b2c-qa",
  //     sas : "sp=racwdl&st=2021-08-30T07:47:19Z&se=2021-12-31T18:29:59Z&spr=https&sv=2020-08-04&sr=c&sig=eciyNSM%2F%2FYhHBXicIN3uoU78qTC6TfbFVWygPdeWoDI%3D"
  //   }

  // // xrconnect-b2c-dev container 
  // azure: 
  //   {
  //     azure_account_name: "xrconn",
  //     containerName: "xrconnect-b2c-dev",
  //     sas : "sp=racwdl&st=2021-08-30T07:59:06Z&se=2021-12-31T18:29:59Z&spr=https&sv=2020-08-04&sr=c&sig=DQHbudvQbj0sN%2FnntMlzxeuNr%2Be6fRBrv6SglRGYVtg%3D"
  //   }
};
