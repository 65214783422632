import { Injectable } from "@angular/core";
import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";
import { environment } from "src/environments/environment.prod";

@Injectable({
  providedIn: "root",
})
export class AzureBlobStorageService {
  accountName = environment.azure.azure_account_name;
  containerName = environment.azure.containerName;
  fileUrlToSave1: any;
  fileUrlToSave: any;

  constructor() {}

  public async listFiles(): Promise<string[]> {
    let result: string[] = [];
    // List the blob(s) in the container.
    let blobs = this.containerClient().listBlobsFlat();
    for await (const blob of blobs) {
      result.push(blob.name);
    }
    return result;
  }

  public async deleteFile(sas: string, name: string, handler: () => void) {
    this.containerClient(sas)
      .deleteBlob(name)
      .then(() => {
        handler();
      });
    // console.log(this.deleteResponse);
    console.log("Blob was deleted successfully.");
  }

  // Download blob content v2
  public async downloadFile1(name: string, handler: (blob: Blob) => void) {
    try {
      console.log("// Download blob content...");
      const BlobClient = this.containerClient().getBlockBlobClient(name);
      const downloadBlockBlobResponse = await BlobClient.download();
      console.log(
        `Downloaded blob content - ${downloadBlockBlobResponse.blobBody.then(
          (blob) => {
            handler(blob);
          }
        )},`
      );
      console.log(
        `requestId - ${downloadBlockBlobResponse.requestId}, statusCode - ${downloadBlockBlobResponse._response.status}\n`
      );
      console.log("Blob was downloaded successfully.");
    } catch (err) {
      console.log(`download() failed as expected,`);
      console.log(
        `requestId - ${err.details.requestId}, statusCode - ${err.statusCode}, errorCode - ${err.details.errorCode}\n`
      );
    }
  }

  // upload Content file v2
  public async uploadApplicationFiles(
    sas: string,
    content: Blob,
    name: string,
    fn: string,
    handler: () => void
  ) {
    try {
      const foldername = fn;
      const blockBlobClient = this.containerClient(sas).getBlockBlobClient(
        foldername + name
      );
      console.log("Uploading to Azure storage as blob:\t", name);
      const response = await blockBlobClient.uploadData(content, {
        blobHTTPHeaders: { blobContentType: content.type },
      });
      console.log("Blob was uploaded successfully.");
      this.fileUrlToSave = blockBlobClient.url
        .split("?")[0]
        .replace("%2F", "/");
      console.log(
        "StatusCode: ",
        response._response.status,
        "--->",
        "fileURL: ",
        this.fileUrlToSave
      );
      return this.fileUrlToSave;
    } catch (err) {
      console.log(
        `uploadFile failed, requestId - ${err.details.requestId}, statusCode - ${err.statusCode}, errorCode - ${err.details.errorCode}`
      );
      throw err;
    }
  }

  private containerClient(sas?: string): ContainerClient {
    let token = "";
    sas =
      environment.azure.sas;

    if (sas) {
      token = sas;
    }
    return new BlobServiceClient(
      `https://${this.accountName}.blob.core.windows.net?${token}`
    ).getContainerClient(this.containerName);
  }
}
